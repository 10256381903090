import '../css/App.css';
import * as React from 'react';

function App(props: {
  author: String;
  dt: String;
  list: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined)[];
}) {
  const [isOpen, setOpen] = React.useState(false)

  return (
    <>
      <div id="tagBar">
        <span className='author'>{props.author}</span>
        <span className='dt'>{props.dt}</span>
        {props.list.map((item: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, key: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined) => {
          return (
            <span className='tag'>{item}</span>
          )
        })}
      </div>
    </>
  );
}

export default App;