import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './css/index.css';
import MyApp from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import About from './components/About';
import Tutorials from './components/Tutorials';
import Projects from './components/Projects';
import { ThemeContext } from './theme-context';

const myDoc = document.getElementById('root');
const root = createRoot(myDoc!);
root.render(<App />);

function App() {

  function headerScroll() {
    window.addEventListener('scroll', (event) => {
      var scroll = window.scrollY;
      var appHeader = document.querySelector(".App-logo");
      var socButtons = document.querySelector(".social_buttons");
      if (scroll >= 20) {
        appHeader!.classList.add('app-header-top');
        socButtons!.classList.add('social_buttons_scroll');
      } else {
        appHeader!.classList.remove('app-header-top');
        socButtons!.classList.remove('social_buttons_scroll');
      }
    })
  };
  headerScroll();

  const moonSVG = () => {
    return (
      <svg fill="none" viewBox="2 2 20 20" width="12" height="12" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="currentColor" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z">
        </path>
      </svg>
    );
  };

  const sunSVG = () => {
    return (
      <svg fill="none" viewBox="3 3 18 18" width="12" height="12" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="currentColor" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z">
        </path>
      </svg>
    );
  };

  const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

  const getDefaultTheme = (): string => {
    const localStorageTheme = localStorage.getItem('theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    return localStorageTheme || browserDefault;
  };

  const getDefaultIcon = (): React.JSX.Element => {
    const localStorageTheme = localStorage.getItem('theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    if (localStorageTheme === 'light' || browserDefault === 'light') {
      return sunSVG();
    } else {
      return moonSVG();
    }
  };

  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    setThemeIcon(isCurrentDark ? sunSVG : moonSVG);
    localStorage.setItem('theme', isCurrentDark ? 'light' : 'dark');
  };

  const [theme, setTheme] = React.useState(getDefaultTheme);
  const [themeIcon, setThemeIcon] = React.useState<React.JSX.Element>(getDefaultIcon);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme}`}>
        <Router>
          <Sidebar />
          <Routes>
            <Route path="/" element={<MyApp currTheme={theme} />} />
            <Route path="/About" element={<About />} />
            <Route path="/Tutorials" element={<Tutorials currTheme={theme} />} />
            <Route path="/Projects" element={<Projects />} />
          </Routes>
        </Router >
        <div className="theme-toggle">
          {themeIcon}
          <input
            title="Change Theme"
            type="checkbox"
            className="theme-toggle"
            id="switch"
            onClick={handleThemeChange}>
          </input>
          <label htmlFor="switch"></label>

        </div>
      </div>
    </ThemeContext.Provider>
  )
};

export default App;