import '../css/App.css';
import * as React from 'react';
import { MenuDivider } from "@szhsin/react-menu";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import { FcHome } from "react-icons/fc";
import { FcInfo } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import { FcSerialTasks } from "react-icons/fc";

function MySidebar() {
  const [isOpen, setOpen] = React.useState(false)
  const [collapsed, setCollapsed] = React.useState(true);

  const menuItemStyles = {
    root: { border: '0px', },
    button: {
      fontSize: '1.9em',
      fontVariant: 'all-small-caps',
    },
  }

  return (
    <>
      <div id="navBar">
        <Sidebar id="sidebar" collapsed={collapsed} collapsedWidth='50px;' transitionDuration={700}>
          <div id="sidebar-handle" >
            <Hamburger rounded toggled={isOpen} toggle={setOpen} easing="ease-in" onToggle={toggled => { setCollapsed(!collapsed) }} />
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem className='nav_item' icon={<FcHome />} component={<Link to="/" />}> Home</MenuItem>
            <MenuItem className='nav_item' icon={<FcInfo />} component={<Link to="/About" />}> About</MenuItem>
            <MenuItem className='nav_item' icon={<FcSerialTasks />} component={<Link to="/Tutorials" />}> Examples</MenuItem>
            <MenuItem className='nav_item' icon={<FcMindMap />} component={<Link to="/Projects" />}> Projects</MenuItem>
          </Menu>

        </Sidebar>
      </div>
    </>
  );
}

export default MySidebar;