import '../../css/App.css';
import * as React from 'react';
import { FcApproval } from "react-icons/fc";
import Tags from "../../components/Tags";

function App() {
  return (
    <div className='entry_wrapper'>
      <div className='entry_header'>
        <FcApproval />&nbsp;
        First Post - New Site Layout
      </div>
      <div className='entry_body'>
        <p>
          Welcome back to the site with a fresh new layout. I decided to move hosts and launch a new
          design for the site. I am on the fence as to whether I will refactor any old posts from the
          old site for the new one. Probably those that were most useful.
        </p>
        <p>
          With that said, there is not much to see here yet. You can always check in with me via
          social links on the top right. Make it a great day!
        </p>

      </div>

      <div className='entry_meta'>
        <Tags
          author=""
          dt="Nov 21, 2024"
          list={["First!", "Design"]}
        />
      </div>
    </div>
  )
}

export default App;