import '../css/App.css';
import * as React from 'react';
import { IoLogoGithub } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

function App() {

  return (

    <header className="App-header" >
      <div className='App-logo app-header-fade'>
        Projects
      </div>
      <div className="social_buttons">
        <a href="https://www.linkedin.com/in/chaceprochazka">
          <FaLinkedin />
        </a>&nbsp;
        <a href="https://github.com/chacepro">
          <IoLogoGithub />
        </a>&nbsp;
        <a href="mailto:chace@designsbychace.com">
          <MdOutlineMailOutline />
        </a>
      </div>
      <div id="main_content">
        blah blah blah
      </div>
    </header>

  );
}

export default App;
