import '../../css/App.css';
import * as React from 'react';
// @ts-ignore
import CodePen from 'codepen-react';
import Tags from "../../components/Tags";
import { FcAlarmClock } from "react-icons/fc";
import { CopyBlock, obsidian } from 'react-code-blocks';

const code = `const sunrise = 1731245882000;
const sunset = 1731282496000;
`;

function App(props: { currTheme: any; }) {
  return (
    <div className='entry_wrapper'>
      <div className='entry_header'>
        <FcAlarmClock />&nbsp;
        Day & Night Progress Widget
      </div>
      <div className='entry_body'>

        <CodePen
          user="chacepro"
          hash="MWNzEKJ"
          theme={props.currTheme}
          editable={false}
        /><br /><br />

        <p>
          <h3>Background</h3>
          While working on a side project application I was dealing with weather data, this included
          sunrise and sunset time based on your location. I wanted a way to display the progress of
          the current time throughout the day as it related to how much light and dark hours were
          available for the given date. One of my Suunto watches had a need widget that did something
          very similar so I decided to mimic what it was doing in a web based application and this
          is what I came up with.
        </p>
        <p>
          <h3>The Code</h3>
          There are two constants that are hard-coded in the example. These values are the sunset
          and sunrise times when I was working on the Codepen. In my implementation of this I
          fetch the current date's sunrise and sunset times from OpenWeather. In this example
          depending when you look at it won't be exact where the marker is during actual sunrise
          and sunset, I think you get the point I am making.
          <CopyBlock
            text={code}
            language={"javascript"}
            showLineNumbers={false}
            theme={obsidian}
            codeBlockStyle={false}
          />
        </p>

      </div>
      <div className='entry_meta'>
        <Tags
          author=""
          dt="Nov 20, 2024"
          list={["Javascript", "CSS", "SVG", "Time"]}
        />
      </div>
    </div>
  )
}

export default App;